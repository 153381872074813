<template>
    <div>
        <base-header base-title="Report History Reservasi Pasien"></base-header>
        <div class="kt-content kt-grid__item kt-grid__item--fluid" id="kt_content">
            <div class="kt-portlet kt-portlet--mobile">
                <div class="kt-portlet__head kt-portlet__head--lg">
                    <base-title :head-title="headTitle"></base-title>
                </div>
                <div class="kt-portlet__body">
                    <template v-if="!isLoadingDetail">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group row mb-2">
                                <label class="col-3 col-form-label font-weight-bold">{{ $t('common.name') }}:</label>
                                <label class="col-7 col-form-label">{{patient.patientName}}</label>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group row mb-2">
                                <label class="col-3 col-form-label font-weight-bold">{{ $t('common.tanggalLahir') }}:</label>
                                <label class="col-7 col-form-label">{{formatDate(patient.dateOfBirth)}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group row mb-2">
                                <label class="col-3 col-form-label font-weight-bold">{{ $t('common.gender') }}:</label>
                                <label class="col-7 col-form-label">{{patient.jenisKelamin.name}}</label>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group row mb-2">
                                <label class="col-3 col-form-label font-weight-bold">{{ $t('common.phoneNo') }}:</label>
                                <label class="col-7 col-form-label">{{formatTelepon(patient.mobilePhone1)}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group row mb-2">
                                <label class="col-3 col-form-label font-weight-bold">{{ $t('common.email') }}:</label>
                                <label class="col-7 col-form-label">{{patient.email}}</label>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group row mb-2">
                                <label class="col-3 col-form-label font-weight-bold">{{ $t('common.medicalNo') }}:</label>
                                <label class="col-7 col-form-label">{{patient.medicalNo}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-md-8">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label class="col-md-5 col-form-label font-weight-bold">Tanggal Kunjungan : </label>
                                        <div class="col-md-7 pt-1">
                                            <div class="input-group input-group-sm date">
                                                <div class="input-group-prepend">
                                                <span class="input-group-text">
                                                    <i class="la la-calendar-o glyphicon-th"></i>
                                                </span>
                                                </div>
                                                <date-range-picker
                                                    :changedValueStart.sync="dateStart"
                                                    :changedValueEnd.sync="dateEnd"
                                                    id-date-picker="filter_rangedatepicker"
                                                    picker-value
                                                    place-holder="DD/MM/YYYY - DD/MM/YYYY"
                                                    :periodeAwal="dateStart"
                                                    :periodeAkhir="dateEnd"
                                                ></date-range-picker>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <button
                                            type="button"
                                            @click="submitSearch()"
                                            class="btn btn-sm btn-default btn-bold btn-upper mt-1 ml-2"
                                        >
                                            <i class="flaticon2-search"></i> {{ $t('button.search') }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="dt-buttons btn-group pull-right" v-if="isLoaded">
                                <download-excel
                                    class="btn btn-secondary buttons-excel buttons-html5"
                                    :fetch="exportExcelData"
                                    :export-fields="json_fields"
                                    worksheet="My Worksheet"
                                    name="Laporan History Reservasi Pasien.xls"
                                >
                                    <span>Excel</span>
                                </download-excel>
                                <button class="btn btn-secondary buttons-pdf buttons-html5" tabindex="0" aria-controls="table_reservation" type="button" @click="downloadPdf">
                                <span>PDF</span></button>
                            </div>
                        </div>
                    </div>
                    </template>
                   <div
                        v-else
                        class="m-auto p-5 d-flex flex-row h-100"
                        >
                        <div class="kt-spinner kt-spinner--v2 kt-spinner--lg kt-spinner--primary my-auto"></div>
                        <h5 class="my-auto pl-5">Loading ...</h5>
                    </div>
                    <div v-if="showLoading" class="m-auto p-5 d-flex flex-row h-100">
                        <div class="kt-spinner kt-spinner--v2 kt-spinner--lg kt-spinner--primary my-auto"></div>
                        <h5 class="my-auto pl-5">Loading ...</h5>
                    </div>
                    <div v-if="isLoaded">
                        <br/>
                        <v-client-table ref="table" :data="tableData" :columns="columns" :options="options" v-on:filter="listenTo">
                        </v-client-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CheckPermission from "./../../services/checkPermission.service";
const checkPermission = new CheckPermission();
import BaseTitle from "./../_base/BaseTitle";
import BaseHeader from "./../_base/BaseHeader";
import DateRangePicker from "./../_general/CustomDateRangePicker";
import DaftarPerujuk from "./../../constants/report-daftar-perujuk";
import DataTables from "./../_table/DataTables";
import { RepositoryFactory } from "./../../repositories/RepositoryFactory";
const ReportRepository = RepositoryFactory.get("report");
const PatientRepository = RepositoryFactory.get("patient");
import StatusReservasi from './../../constants/status-reservasi-enum';

export default {
    components: {
        BaseHeader,
        BaseTitle,
        DateRangePicker,
        DataTables
    },
    data() {
        var vx = this;
        return {
            isLoadingDetail: true,
            patient: null,
            id: null,
            headTitle: "Report History Reservasi Pasien",
            dateStart: moment('2020-02-01').format('YYYY-MM-DD'),
            dateEnd: moment().format('YYYY-MM-DD'),
            showLoading:false,
            isLoaded:false,
            data: null,
            tableData: [],
            columns: [
                'createdDate',
                'nomorRegistrasi',
                'namaDokter',
                'tanggalKunjungan',
                'jamKunjungan',
                'reservasiVia',
                'jenisPenjamin',
                'status',
                'updateStatus'
           ],
           options:{
                filterByColumn: true,
                headings: {
                    createdDate: "Tanggal Pembuatan",
                    nomorRegistrasi: "Nomor Registrasi HIS" ,
                    namaDokter: "Dokter",
                    tanggalKunjungan: "Jadwal Kunjungan",
                    jamKunjungan: "Jam Kunjungan" ,
                    reservasiVia: "Reservasi Via",
                    jenisPenjamin: "Pembayaran",
                    status: "Status",
                    updateStatus: "Waktu Update Status"
                },
                sortIcon: {
                    base : 'fa',
                    is: 'fa-sort',
                    up: 'fa-sort-up',
                    down: 'fa-sort-down'
                },
                dateColumns: ['createdDate', 'tanggalKunjungan', 'updateStatus'],
                dateFormat: 'DD-MMM-YYYY HH:mm:ss',
                listColumns: {
                    reservasiVia: [
                        {
                            id: 'MOBILE',
                            text: 'MOBILE'
                        },
                        {
                            id: 'WEB',
                            text: 'WEB'
                        }
                    ],
                    status: [
                        {
                            id: 'Baru',
                            text: 'Baru'
                        },
                        {
                            id: 'Terkonfirmasi',
                            text: 'Terkonfirmasi'
                        },
                        {
                            id: 'Dibatalkan',
                            text: 'Dibatalkan'
                        },
                        {
                            id: 'Pasien Datang',
                            text: 'Pasien Datang'
                        }
                    ],
                },
                initFilters: {
                    reservasiVia: 'MOBILE',
                    status: 'Pasien Datang'
                },
                orderBy:{ column: 'updateStatus', ascending:true },
                templates: {
                    createdDate: function (h, row, index) {
                        return moment(row.createdDate).format('DD-MMM-YYYY')
                    },
                    tanggalKunjungan: function (h, row, index) {
                        return moment(row.tanggalKunjungan).format('DD-MMM-YYYY')
                    }
                },
            },
            json_fields: {}
        }
    },
    watch: {

    },
    methods: {
        checkNull: function(data){
            return (data) ? data : '';
        },
        exportExcelData: function(param){
            const filteredData = [...this.$refs.table.allFilteredData];
            let dataExcel = filteredData.map(x => {
                var temp = {...x};
                temp.createdDate = moment(x.createdDate).format('DD-MMM-YYYY');
                temp.tanggalKunjungan = moment(x.tanggalKunjungan).format('DD-MMM-YYYY');
                temp.updateStatus =  moment(x.updateStatus).format('DD-MMM-YYYY HH:mm:ss');
                return temp;
            })
            return dataExcel;
        },
        downloadPdf: function(){
            var data = this.exportExcelData();
            var column = []
            Object.keys(this.options.headings).forEach(key => {
            column.push({
                title: this.options.headings[key],
                dataKey: key
            })
            });
            var doc = new jsPDF('l', 'pt');
            var text = "Laporan History Reservasi Pasien"
            var xOffset = (doc.internal.pageSize.width / 2) - (doc.getStringUnitWidth(text) * doc.internal.getFontSize() / 2);
            doc.text(text, xOffset, 30);
            doc.setFontSize(9);
            var namaPasien = "Nama Pasien : "+this.patient.patientName;
            var tanggalLahir = "Tanggal Lahir : " + this.formatDate(this.patient.dateOfBirth);
            var jenisKelamin = "Jenis Kelamin : " + this.patient.jenisKelamin.name;
            var nomorTelepon = "Nomor Telepon : " + this.formatTelepon(this.patient.mobilePhone1);
            var email = "Email : " + this.patient.email;
            var medicalRecord = "Medical Record : " + this.patient.medicalNo
            var periode = "Periode Kunjungan : " + this.formatDate(this.dateStart) + ' - ' + this.formatDate(this.dateEnd);
            doc.text(namaPasien, 40 , 50);
            doc.text(tanggalLahir, 40 , 65);
            doc.text(jenisKelamin, 40 , 80);
            doc.text(nomorTelepon, 40 , 95);
            doc.text(email, 40 , 110);
            doc.text(medicalRecord, 40 , 125);
            doc.text(periode, 40 , 140);

            doc.autoTable(column, data, {
                startY: 160,
                styles: {
                    fontSize: 7,
                },
            });
            doc.save('Laporan History Reservasi Pasien.pdf');
        },
        listenTo: function(a) {
            var query = JSON.parse(this.$refs.table.q);
            var column = [...this.columns];
            helpers.filterColor(query, column);
        },
        jamKunjungan: function(data){
            const start = (data.jamMulai) ? data.jamMulai.substr(0, 5) : '';
            const selesai = (data.jamSelesai) ? data.jamSelesai.substr(0, 5) : '';
            return start+' - '+selesai;
        },
        reservasiVia : function(data){
            var checkRegex = data.match(/[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}/g);
            return (checkRegex != null) ? 'MOBILE' : 'WEB'
        },
        formatTelepon: function(data){
            return helpers.formatPhoneNumber(data);
        },
        formatDate: function (data) {
            var momentDate = moment(data);
            return(momentDate.format("DD-MMM-YYYY"));
        },
        submitSearch: function () {
            this.showLoading = true;
            this.isLoaded = false;
            this.fetch();
        },
        constructData: function(data){
            var dataResult = data.map(x => {
                var temp = {
                    createdDate: moment(x.createdDateTime),
                    nomorRegistrasi: x.nomorRegistrasi,
                    namaDokter: x.jadwal.dokter.nama,
                    tanggalKunjungan: moment(x.tanggalKunjungan),
                    jamKunjungan: this.jamKunjungan(x.jadwal),
                    reservasiVia: this.reservasiVia(x.createdBy),
                    jenisPenjamin: x.jenisPenjamin.nama,
                    status: StatusReservasi[x.status].title,
                    updateStatus: moment(x.lastUpdateStatus)
                }
                return temp;
            });
            return dataResult;
        },
        async fetch(resolve) {
                var params = {
                    // sort: ['kategoriNama,ASC', 'id,ASC'],
                    'tanggalKunjungan.greaterOrEqualThan': this.dateStart,
                    'tanggalKunjungan.lessOrEqualThan': this.dateEnd,
                    'patientId.in' : this.id
                };
                ReportRepository.reportReservasiHistroy(params)
                .then(response => {
                        this.data = (response.data.length > 0) ? response.data[0] : {reservasis:[]};
                        this.tableData = this.constructData(this.data.reservasis);
                        this.showLoading = false;
                        this.isLoaded = true;
                        setTimeout(() => {
                            this.listenTo();
                        }, 100);
                }).catch(error => {
                    var msg = (error.message) ? error.message : error;
                    this.showToastError('Report Reservasi History : ' + msg);
                })

        },
        showToastError: function(message) {
            toastr.error(message);
        },
        onInit: function(){
            this.isLoadingDetail = true;
            this.id = this.$route.params.id;
            PatientRepository.getPatient(this.id).then(response => {
                this.patient = response.data;
                this.isLoadingDetail = false;
            })
        }
    },
    created() {
        this.onInit()
        this.json_fields = helpers.objectFlip(this.options.headings);
    }
}
</script>
